import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './i18n';

serviceWorkerRegistration.register();


if (document.getElementById('root')?.hasChildNodes()) {
  const root = hydrateRoot(
    document.getElementById('root')!,
    <React.StrictMode>
      <App />
    </React.StrictMode>

  );
} else {
  const root = createRoot(
    document.getElementById('root')!
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
